import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Logo from './Logo'

const isSMILAvailable = () => {
  if (typeof window === 'undefined') {
    return true;
  } else {
    return window.navigator.userAgent.match(/(MSIE|Trident|Edge)\//) === null;
  }
}

function Hero(props) {
  return (
    <div className={`hero ${props.backgroundClass}`}>
      <h1 className="hero-title" aria-label={props.heroTitle}>
        <Logo/>
      </h1>
      <div className="hero-subtitle">
        {props.subtitle}
      </div>
      <div>
        {props.topLinks && props.topLinks.length > 0 && (
          props.topLinks.map((link, i) => {
            return (
              <Link href={link.href} key={i}>
                <a className="" key={i}>
                  {link.text}
                </a>
              </Link>
            )
          })
        )}
      </div>
    </div>
  )
}

Hero.propTypes = {
  backgroundClass: PropTypes.string,
  topLinks: PropTypes.array,
  heroTitle: PropTypes.string,
  subtitle: PropTypes.string,
}

Hero.defaultProps = {
  backgroundClass: '',
  topLinks: [],
  heroTitle: '',
  subtitle: '',
}

export default Hero
