import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

function Patron(props) {
  return (
    <div className={`patron ${props.backgroundClass}` + (props.isPersonal ? 'personal-patron' : '')}>
      <a href={props.url}>
        <h3>{props.name}</h3>
        <div className='patron-image-container'>
          <img src={props.imgsrc}
               srcset={props.imgsrcset}
               alt={props.name} />
        </div>
      </a>
    </div>
  )
}

Patron.propTypes = {
  backgroundClass: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
  imgsrc: PropTypes.string,
  imgsrcset: PropTypes.string,
  isPersonal: PropTypes.bool,
}

Patron.defaultProps = {
  backgroundClass: '',
  name: '',
  url: '',
  imgsrc: '',
  imgsrcset: '',
}

export default Patron
