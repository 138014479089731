import React from 'react'
import Head from 'next/head'
import Link from 'next/link'
import Hero from '../components/Hero'
import Menu from '../components/Menu'
import Block from '../components/Block'
import Patron from '../components/Patron'
import LittleInfo from '../components/LittleInfo'
import RegistrationButton from '../components/RegistrationButton'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab, faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { useRouter } from 'next/router'
import { Textfit } from 'react-textfit';

export default () => {
  const { basePath } = useRouter();

  return <div id="main-container">
    <Head>
      <title>PyCon mini Hiroshima 2020</title>
    </Head>
    <header>
      <Menu />
    </header>
    <div role='main'>
      <Hero heroTitle='PyCon mini Hiroshima 2020' />
      <RegistrationButton />
      <div style={{textAlign: 'center', margin: '1em 0'}}>
        <span className='remarkable-button'>終了しました! </span>
        <p>たくさんのご参加ありがとうございました!</p>
      </div>
      <Block>
        <div className='event-info'>
          <Textfit mode='single'>
            2020年<span className='date-number'>10</span>月<span className='date-number'>10</span>日(<span className='date-day-of-week'>土</span>)
          </Textfit>
          <Textfit mode='single'>
            14:00-17:00
          </Textfit>
          <Textfit mode='single'>
            オンライン (Zoom)
          </Textfit>
          <Textfit mode='single'>
            参加費 無料
          </Textfit>
          <Textfit mode='single'>
            テーマ <span className='theme'>“つながろう Python”</span>
          </Textfit>
          <Textfit mode='single'>
            <a href='https://twitter.com/search?q=%23PyConHiro&src=typed_query'>
              <span style={{color: '#1DA1F2'}}><FontAwesomeIcon icon={faTwitter} /></span> #PyConHiro
            </a>
          </Textfit>
          <LittleInfo>
            <ul>
              <li>主催 PyCon mini Hiroshima 実行委員会</li>
            </ul>
          </LittleInfo>
        </div>
      </Block>

      <Block title='スケジュール、公開。' >
        <p>
          <Link href='schedule'><a>スケジュールを公開</a></Link>しました。
          <LittleInfo>2020年9月10日</LittleInfo>
        </p>
      </Block>

      <Block title='参加登録'
             subtitle='受付中'>
        <div>
          <Link href='https://pycon-hiroshima.connpass.com/event/186849/'><a>connpass</a></Link>にて参加登録受付中です。
        </div>
      </Block>

      <a name="greeting"/>
      <Block>
        <div className='general-info-vivid'>
          <h2>ごあいさつ</h2>
<p>
広島の Python コミュニティは
PyCon mini Hiroshima の運営ミーティングをずっとオンラインで行っていました。
2020年3月からは「すごい広島 with Python」という毎月の勉強会も
オンライン開催しています。
</p>
<p>
たとえオンラインであっても
「地域を超えて Python に関わる人々と、もっとつながりたい、つながり続けたい」
と考えて、私たちは
10月10日（土曜）に PyCon mini Hiroshima 2020 を開催します。
</p>
<p>
私たちの今回のテーマは「つながろう Python」です。
</p>
<p>
「つながる Python」は PyCon JP 2012 のテーマでした。
Python はいろいろな技術やシステムをつなぐ手段として、便利なツールです。
一方で Python でつながった人や組織やコミュニティも、
私たちの経験、宝物になりました。
</p>
<p>
今回は広島から、私たちの地域に向けて、また地域を超えて、
Python の技術とコミュニティを愛する人たちに「つながろう」と呼び掛けます。
</p>
        </div>
      </Block>

      <a name="keynote"/>
      <Block title='基調講演: 寺田　学'
      >
        <figure className='person-picture' >
          <img src={`${basePath}/images/terada.jpg`} alt='写真: 寺田 学さん'/>
        </figure>
        <div className='general-info'>
          <h4>寺田　学 (Manabu TERADA)</h4>
          <ul className='social-links'>
            <li className='twitter'><a href='https://twitter.com/terapyon'><FontAwesomeIcon icon={faTwitter} /></a></li>
          </ul>
          <div>
Python Web関係の業務を中心にコンサルティングや構築を(株)CMSコミュニケーションズ代表取締役として手がけている。
2010年から国内のPythonコミュニティに積極的に関連し、PyCon JPの開催に尽力した。
2013年3月からは一般社団法人PyCon JP Association 代表理事を務める。
その他のOSS関係コミュニティを主宰またはスタッフとして活動中。
一般社団法人Pythonエンジニア育成推進協会顧問理事として、Pythonの教育に積極的に関連している。
最近はPythonの魅力を伝えるべく、初心者向けや機械学習分野のPython講師を精力的に務めてたり、
Pythonをはじめとした技術話題を扱う <a href="https://podcast.terapyon.net/">Podcast「terapyon channel」</a> を配信中。
機械学習図鑑(翔泳社: 2019年4月)を共著、Pythonによるあたらしいデータ分析の教科書(翔泳社:2018年9月)を共著、
スラスラわかるPython(翔泳社:2017年8月)を監修、Pythonハッカーガイドブック(マイナビ: 2020年5月)を監訳、その他執筆活動も行っている。
          </div>
        </div>

      </Block>

      <a name="slack"/>
      <Block title='Slack で「つながろう」' >
        <p>
          今年は、参加者が 開催日より前から 交流できるように PyCon Hiroshima Slack ワークスペースをご用意いたしました。
        </p>
        <p>
          開催日より前からこのSlackでアイスブレイクしたり、交流したりしましょう。
        </p>
        <p>
          参加登録画面の「このイベントに申し込む」を実行していただくと表示される「参加者への情報」でご確認ください。
          なるべくお早めにご参加ください。
        </p>
        <p>
          運営を手伝いたい、支援したい、という方も大歓迎です。
        </p>
      </Block>

      <a name="osc"/>
      <Block title='OSC で「つながろう」' >
        <p>
          9月19日（土曜日）10時から10時45分に
          <br/>
          <a href="https://ospn.connpass.com/event/181686/">Open Source Conference 2020 Online/Hiroshima</a>
          <br/>
          にてオンラインミーティング「広島のPythonコミュニティとつながろう」を行いました。
        </p>
        <p>
          運営チームにご興味やご質問がおありの方はお気軽にご参加ください。
        </p>
      </Block>

    <Block title='すごい広島 with Python'
           subtitle='月末の水曜は Python でミートアップ'>
      月末の水曜の夜に<a href="https://pycon-hiroshima.connpass.com/event/">「すごい広島 with Python」</a> をオンライン開催しています。
    </Block>

    <Block title='チーム'>
      <p>
        👥 <Link href='team'><a>PyCon mini Hiroshima 2020 のチーム</a></Link> 👥
      </p>
      <p>
        今年の<Link href='team'><a>チーム</a></Link>と一緒に運営を行いませんか? まずは様子を見てみたいという方は是非
        <a href="https://pycon-hiroshima.connpass.com/event/">PyCon mini Hiroshimaのconnpassグループ</a>
        の運営ミーティング (オンライン開催) に「にぎやかし枠」でお申し込みください。
      </p>
    </Block>

    <Block title='過去の記録'
      backgroundClass='bg-dark'
    >
      <ul>
        <li><a href='/2019'>2019年の記録</a></li>
        <li><a href='/2018'>2018年の記録</a></li>
        <li><a href='/2016'>2016年の記録</a></li>
        <li><a href='/2015'>2015年の記録</a></li>
      </ul>
    </Block>
    </div>

    <Footer/>
  </div>
}
