import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

const LittleInfo = ({children}) => {
  return (
    <span style={{fontSize: 'smaller', color: 'gray'}}>
      {children}
    </span>
  )
}

export default LittleInfo

