const keyColor = '#162e5f'
import { useRouter } from 'next/router'

function Logo(props) {
  const { basePath } = useRouter();

  return (
      <img src={`${basePath}/images/logo2020.svg`}
               alt="PyCon mini Hiroshima 2020" style={{width:"100%"}} />
  )
}
export default Logo
